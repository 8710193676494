<template>
  <!-- 存证列表 -->
  <div class="padding24" id="cunzheng">
    <div v-show="$route.meta.isShow">
      <div class="top">
        <div class="left"></div>
        <div class="right">
          <a-select
            v-model="getData.productCatValue"
            style="width: 120px"
            :getPopupContainer="
              (triggerNode) => triggerNode.parentNode || document.body
            "
            @change="productCatValueChange"
          >
            <a-select-option v-for="item in productCatValueArr" :key="item.key">
              {{ item.value }}
            </a-select-option>
          </a-select>
          <span>
            日期 :
            <a-range-picker
              :value="rangePicker"
              format="YYYY-MM-DD"
              :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
              @change="onChangeDate"
            />
          </span>
          <a-input
            v-model="getData.submitterName"
            placeholder="请输入存证者姓名"
            style="width: 200px"
            @pressEnter="searchClick"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-input
            v-model="getData.productName"
            placeholder="请输入作品名称"
            style="width: 200px"
            @pressEnter="searchClick"
          >
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-button icon="search" type="primary" @click="searchClick">
            搜索
          </a-button>
        </div>
      </div>
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        :loading="tableLoading"
      >
        <span slot="blockBuildStatus" slot-scope="blockBuildStatus, record">
          <span v-if="record.blockBuildStatus == 0">未生成</span>
          <span v-if="record.blockBuildStatus == 1">已生成</span>
        </span>
        <span slot="operation" slot-scope="operation, record">
          <a v-if="isShowDetail" @click="detailClick(record)">详情</a>
        </span>
      </a-table>
      <!-- 分页功能 -->
      <MyPagination
        :count="count"
        :pageNo="getData.pageNo"
        @showSizeChangeFn="showSizeChangeFn"
        v-show="tableData.length > 0"
      />
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
    </div>
    <router-view />
  </div>
</template>

<script>
import { CunZhengListApi } from "@/request/api/certificate.js";
import MyPagination from "@/components/pagination/MyPagination";
import moment from "moment";
import { codeFn } from "@/utils/tools";
export default {
  components: { MyPagination },
  created() {
    let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
    this.productCatValueArr.push(...dictionaryObj.proCat);
    if (codeFn("/frame-web/admin/copyright/deposit/certificate/details")) {
      this.isShowDetail = true;
    }
  },
  data() {
    return {
      rangePicker: [],
      getData: {
        pageNo: 1,
        pageSize: 20,
        productName: "",
        submitterName: "",
        productCatValue: "",
        startTime: "",
        endTime: "",
      },

      count: 0,
      columns: [
        {
          title: "作品名称",
          dataIndex: "productName",
          key: "productName",
          width: "20%",
        },
        {
          title: "提交人名称",
          dataIndex: "submitterName",
          key: "submitterName",
          width: "20%",
        },
        {
          title: "作品分类",
          dataIndex: "productCatName",
          key: "productCatName",
          width: "20%",
        },
        {
          title: "状态",
          dataIndex: "blockBuildStatus",
          key: "blockBuildStatus",
          width: "10%",
          scopedSlots: { customRender: "blockBuildStatus" },
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          width: "10%",
          sorter: true,
          key: "createTime",
          sorter: (a, b) => {
            let aTime = new Date(a.createTime).getTime();
            let bTime = new Date(b.createTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      productCatValueArr: [{ key: "", value: "全部分类" }],
      tableLoading: false,
      isShowDetail: false,
    };
  },
  watch: {
    $route: {
      handler(to) {
        if (to.path == "/certificate/cunZheng") {
          this.listFn();
        }
      },
      immediate: true,
    },
  },
  methods: {
    moment,
    productCatValueChange(value) {
      this.getData.productCatValue = value;
      this.listFn();
    },
    searchClick() {
      this.getData.pageNo = 1;
      this.listFn();
    },
    // 注册日期改变
    onChangeDate(date, dateString) {
      if (dateString[0] != "") {
        this.getData.startTime = dateString[0] + " 00:00:00";
        this.getData.endTime = dateString[1] + " 23:59:59";
        this.rangePicker = [
          moment(dateString[0], "YYYY-MM-DD"),
          moment(dateString[1], "YYYY-MM-DD"),
        ];
        this.getData.pageNo = 1;
        this.listFn();
      } else {
        this.getData.startTime = "";
        this.getData.endTime = "";
        this.rangePicker = [];
        this.getData.pageNo = 1;
        this.listFn();
      }
    },
    detailClick(record) {
      this.$router.push(`/certificate/cunZheng/cunZhengDetaile/${record.id}`);
    },
    listFn() {
      this.tableLoading = true;
      CunZhengListApi(this.getData).then(({ code, data }) => {
        if (code == 200) {
          this.tableLoading = false;
          data.data.map((item, index) => {
            item.key = index;
          });
          this.tableData = data.data;
          this.count = data.count;
        }
      });
    },
    // 分页切换页数
    showSizeChangeFn(current, pageSize) {
      this.getData.pageNo = current;
      this.getData.pageSize = pageSize;
      this.listFn();
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#cunzheng");
    },
  },
};
</script>

<style lang="less" scoped>
#cunzheng {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.top {
  display: flex;
  justify-content: space-between;
  .right {
    /deep/.ant-input-affix-wrapper {
      margin: 0 10px;
    }
  }
}
/deep/.ant-table-wrapper {
  margin-top: 20px;
}
</style>
